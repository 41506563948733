<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="ListIcon"
                  width="24"
                  height="24"
                />
                <h2>Network specifications</h2>
              </div>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Vlan Name"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter vlan name"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Vlan ID No"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Vlan ID"
                    vid="vlan_id"
                    rules="required|max:255|between:2,4094"
                  >
                    <b-form-input
                      v-model="form.vlan_id"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter vlan ID number"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Interface Address"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Interface Address"
                    vid="interface_ip"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter interface address"
                      autocomplete="off"
                      :onchange="(value) => form.interface_ip = value"
                      :model="form.interface_ip"
                      inputs-id="interface_ip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Subnet Mask"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Subnet Mask"
                    vid="subnet_mask"
                    rules="max:45"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter subnet address"
                      autocomplete="off"
                      :onchange="(value) => form.subnet_mask = value"
                      :model="form.subnet_mask"
                      inputs-id="subnet_mask"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Comment"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="comment"
                    rules="required|max:255"
                  >
                    <b-form-input
                      v-model="form.comment"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter your comment"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="GlobeIcon"
                  width="24"
                  height="24"
                />
                <h2>Ports</h2>
              </div>

              <b-form-group
                label="Tagged ports"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tagged_ports"
                >
                  <multiselect
                    v-model="taggedPorts"
                    :options="portsOption"
                    :reduce="option => option.usage"
                    multiple
                    placeholder="Select tagged ports"
                    class="multiselect with-remove"
                    :searchable="false"
                    label="fullName"
                    @select="onSelectPort"
                    @remove="(prop) => onDeselectPort(prop, 'tagged')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Untagged ports"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tagged_ports"
                >
                  <multiselect
                    v-model="untaggedPorts"
                    :options="portsOption"
                    :reduce="option => option.usage"
                    multiple
                    placeholder="Select tagged ports"
                    class="multiselect with-remove"
                    :searchable="false"
                    label="fullName"
                    @select="onSelectPort"
                    @remove="(prop) => onDeselectPort(prop, 'untagged')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Create"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="createdLanModal"
      ref="createdLanModal"
      title="Network Successfully Created!"
      size="md"
      ok-title="Create DHCP Service"
      cancel-title="Ignore"
      centered
      @hidden="goBack"
      @cancel="goBack"
      @ok="goDHCP"
    >
      <p>Your new network (LAN) has been successfully built!</p>
      <p>Would you like to create a DHCP service now, or proceed without it?</p>
    </b-modal>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import Multiselect from 'vue-multiselect'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormIp,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    Multiselect,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      form: {
        name: '',
        vlan_id: '',
        interface_ip: '',
        subnet_mask: '',
        comment: '',
      },
      portsOption: [],
      portsOrigin: [],

      taggedPorts: [
      ],

      untaggedPorts: [
      ],

      required,
      max,
    }
  },
  async created() {
    try {
      const { data } = await axios.get(`/site/${this.siteId}/controller`)
      const { ports } = data.data.controller.model
      const validatedPorts = ports.map(port => ({
        ...port,
        fullName: `${port.interface} - ${port.usage}`,
      }))
      this.portsOption = validatedPorts
      this.portsOrigin = validatedPorts
    } catch (err) {
      console.warn(err)
    }
  },
  methods: {
    onSelectPort(prop) {
      this.portsOption = this.portsOption.filter(port => prop.id !== port.id)
    },
    onDeselectPort(prop, type) {
      this.portsOption = [...this.portsOption, prop]
      if (type === 'untagged') {
        const newPorts = this.untaggedPorts.filter(port => prop.id !== port.id)
        this.untaggedPorts = [...new Map(newPorts.map(item => [item.id, item])).values()]
      } else {
        const newPorts = this.taggedPorts.filter(port => prop.id !== port.id)
        this.taggedPorts = [...new Map(newPorts.map(item => [item.id, item])).values()]
      }
    },
    goDHCP() {
      this.$router.push({
        name: 'site-summary',
        params: { id: this.siteId },
        query: { tab: 2, subTab: 'dhcp_table' },
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const payload = {
            ...this.form,
            tagged_ports: this.taggedPorts.map(port => port.id),
            untagged_ports: this.untaggedPorts.map(port => port.id),
          }
          await axios.post(`site/${this.siteId}/network/setting/lan`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$refs.createdLanModal.show()
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            const untaggedPorts = Object.keys(fields).filter(field => field.startsWith('untagged_ports'))
            const taggedPorts = Object.keys(fields).filter(field => field.startsWith('tagged_ports'))

            const errors = {
              ...fields,
              untagged_ports: untaggedPorts.map(port => fields[port])[0],
              tagged_ports: taggedPorts.map(port => fields[port])[0],
            }

            this.$refs.modelsCreate.setErrors(errors)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
